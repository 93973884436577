import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
// import RightSideBar from "../../RightSidebar.jsx";
// import ResourceCreateAndUpdate from "../../ResourceCreateAndUpdate.jsx";

// import { setResourceModal } from "../../../redux/pagesSlices/sectionSlice";
// import IndexCreateAndUpdate from "../../IndexCreateAndUpdate";
import OwnerCreateAndUpdate from "../../OwnerCreateAndUpdate.jsx";
import { handleModel } from "../../../redux/layoutSlices/modelSlice.js";
import ClientCreateAndUpdate from "../../ClientCreateAndUpdate.jsx";
import GroupCreateAndUpdate from "../../GroupCreateAndUpdate.jsx";
import StudentCreateAndUpdate from "../../StudentCreateAndUpdate.jsx";
import InviteCreateAndUpdate from "../../InviteCreateAndUpdate.jsx";
// import CourseCreateOption from "../../CourseCreateOption.jsx";

function RightSidebarBase({ name, ...props }) {

  const d = useDispatch();


  const state = useSelector((state) => state.model?.modelState?.RightSidebarBase);
  // console.log("🚀 ~ file: rightSidebarBase.jsx:19 ~ RightSidebarBase ~ state:", state)

  const modelsArgs = useSelector(
    (state) => state.model?.modelArgs?.RightSidebarBase
  );



  const callBack = modelsArgs?.callBack;
  const closeModel = () => {
    if (callBack) callBack();
    d(handleModel({ model: "RightSidebarBase", state: false }));
  };
  //

  return (
    <>
      <Offcanvas show={state} onHide={closeModel} {...props} placement="end">
        <Offcanvas.Body>
          {/* index create and update */}
          {/* {modelsArgs?.resource == "couse_options" && (
            <CourseCreateOption onHide={closeModel} />
          )} */}
          {/* index create and update */}
          {modelsArgs?.resource == "Owner" && (
            <OwnerCreateAndUpdate onHide={closeModel} />
          )}
          {modelsArgs?.resource == "Invite" && (
            <InviteCreateAndUpdate onHide={closeModel} />
          )}

          {modelsArgs?.resource == "Contacts" && (
            <ClientCreateAndUpdate onHide={closeModel} />
          )}

          {modelsArgs?.resource == "Users" && (
            <StudentCreateAndUpdate onHide={closeModel} />
          )}

          {modelsArgs?.resource == "Groups" && (
            <GroupCreateAndUpdate onHide={closeModel} />
          )}

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default RightSidebarBase;
