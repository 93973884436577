import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { RiMenu2Fill } from "react-icons/ri";
import { RiBarChartBoxLine } from "react-icons/ri";
import { FaCircle } from "react-icons/fa";
import { TbUserSquare } from "react-icons/tb";
import { CgNotes } from "react-icons/cg";
import { MdLogout } from "react-icons/md";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { BsFileEarmarkText } from "react-icons/bs";
import { CiCirclePlus } from "react-icons/ci";
import { BsArrowClockwise } from "react-icons/bs";
import { TbUserPlus } from "react-icons/tb";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TbGridDots } from "react-icons/tb";
import { RiInboxLine } from "react-icons/ri";
import { FaRegLifeRing } from "react-icons/fa";
import "./Analytics.scss";
import { BiBarChartAlt2 } from "react-icons/bi";
import startIcon from "../../images/inbox.svg";
import avatar from "../../assets/images/avatars/avatar.png";

import Dropdown from "react-bootstrap/Dropdown";
import { redirect_uris } from "../../constants";
import { getAuth } from "firebase/auth";
import { organization } from "../../constants";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const IlmPortalNavbar = ({
  onPortalChange,
  auth,
  logout,
  sidebar = true,
  menuState = false,
  onSwitchOrg = () => { },
  sidebarUris = [],
  children = null,
}) => {
  const { currentUser: user } = getAuth();
  const [sidebarClass, setSidebarClass] = React.useState("");
  const [sidebarClassMobile, setSidebarClassMobile] = React.useState("");

  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const toggleHideClass = () => {
    setSidebarClass((prevClass) =>
      prevClass.includes("active") ? "" : "active"
    );
  };
  const addActiveClass = () => {
    setSidebarClassMobile("show");
  };
  const removeActiveClass = () => {
    setSidebarClassMobile("");
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleAnchorClick = (index, event) => {
    event.stopPropagation(); // Prevent the click event from propagating to the document body
    toggleDropdown(index);
  };

  const handleBodyClick = () => {
    setActiveDropdown(null); // Remove active class when clicking on the body
  };

  const profileHandler = () => {
    console.log("profile111");
    window.open(redirect_uris.Admin + "profile/" + user.uid, "_blank");
  };

  document.body.addEventListener("click", handleBodyClick);
  const customToken = localStorage.getItem("custom-token");

  const [orgData, setOrgData] = useState({});
  console.log("orgData", orgData);
  useEffect(() => {
    const data = organization();
    setOrgData(data);
  }, []);

  return (
    <div className="allStudentsStreamPage">
      <header className="analytics-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-5 align-self-center d-flex gap-3">
              {/* <a href="#" id="hamburger" onClick={addActiveClass}>
                <RiMenu2Fill />
              </a> */}
              <div className="logo-wrapper">
                <a href="#" onClick={(event) => handleAnchorClick(2, event)}>
                  <img
                    src={orgData?.branding?.logo_url || require("./logo.png")}
                    alt="logo-icon"
                  />
                  <strong>{orgData?.displayName}</strong>
                </a>
                {menuState ? (
                  <div
                    className={`dropdown-div ${activeDropdown === 2 ? "active" : ""
                      } `}
                  >
                    <div className="title">
                      <a href="#">
                        <img src={require("./logo.png")} alt="logo-icon" />
                        <strong>ILMIYA</strong>
                      </a>
                    </div>
                    <ul className="list">
                      {/* <li><a href="#"><IoSettingsOutline /><span>Settings</span></a></li> */}
                      <li>
                        <a
                          href={
                            redirect_uris.People + `staff/?token=${customToken}`
                          }
                        >
                          <TbUserPlus />
                          <span>Invite a member</span>
                        </a>
                      </li>
                      {/* <li><a href="#"><BsFileEarmarkText /><span>View Directory</span></a></li> */}
                    </ul>
                    <ul className="list">
                      {/* <li><a href="#"><CiCirclePlus /><span>Create a Account</span></a></li> */}
                      <li onClick={onSwitchOrg}>
                        <a href="#">
                          <BsArrowClockwise />
                          <span className="more-icon">
                            Switch Space <MdKeyboardArrowRight />{" "}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-8 col-7 align-self-center">
              <div className="header-menu">
                <ul className="icons-wrapper">
                  <li>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip-top" style={{position:"fixed"}}>
                          Pages
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={activeDropdown === 0 ? "active" : ""}
                        onClick={(event) => handleAnchorClick(0, event)}
                      >
                        <TbGridDots />
                      </a>
                    </OverlayTrigger>
                    <div
                      className={`dropdown-div  ${activeDropdown === 0 ? "active" : ""
                        } `}
                    >
                      <ul className="page-list">
                        <li
                          onClick={() =>
                            onPortalChange &&
                            typeof onPortalChange === "function" &&
                            onPortalChange("Console")
                          }
                        >
                          <a href="#">
                            <div className="icon-wrapper">
                              <MdOutlineDashboard />
                            </div>
                            <span>Dashboard</span>
                          </a>
                        </li>
                        <li
                          onClick={() =>
                            onPortalChange &&
                            typeof onPortalChange === "function" &&
                            onPortalChange("People")
                          }
                        >
                          <a href="#">
                            <div className="icon-wrapper">
                              <TbUserSquare />
                            </div>
                            <span>People</span>
                          </a>
                        </li>
                        <li
                          onClick={() =>
                            onPortalChange &&
                            typeof onPortalChange === "function" &&
                            onPortalChange("Studio")
                          }
                        >
                          <a href="#">
                            <div className="icon-wrapper">
                              <MdOutlineScreenSearchDesktop />
                            </div>
                            <span>Engage</span>
                          </a>
                        </li>
                        <li
                          onClick={() =>
                            onPortalChange &&
                            typeof onPortalChange === "function" &&
                            onPortalChange("Analytics")
                          }
                        >
                          <a href="#">
                            <div className="icon-wrapper">
                              <RiBarChartBoxLine />
                            </div>
                            <span>Analytics</span>
                          </a>
                        </li>
                        <li
                          onClick={() =>
                            onPortalChange &&
                            typeof onPortalChange === "function" &&
                            onPortalChange("Admin")
                          }
                        >
                          <a href="#">
                            <div className="icon-wrapper">
                              <MdOutlineAdminPanelSettings />
                            </div>
                            <span>Admin</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <ul className="icon-list">
                      <li>
                        <div className="nav-item">
                          <div className="nav-link">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="link"
                                id="dropdown-basic"
                              >
                                {/* <MdOutlineCelebration style={{ width: 24, height: 24 }} /> */}
                                <img
                                  src={startIcon}
                                  style={{ height: 20, width: 20 }}
                                />
                              </Dropdown.Toggle>
                              {/* <Dropdown.Menu className="i-menu">

                                <Link to={"/leaderboard"}>
                                  <div className="portal">
                                    <div className="icon">
                                      <BiBarChartAlt2 />
                                    </div>
                                    <span>Leaderboard</span>
                                  </div>
                                </Link>

                                <Link to={"/badges-page"}>
                                  <div className="portal">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="42"
                                      height="42"
                                      viewBox="0 0 42 42"
                                      fill="none"
                                    >
                                      <path
                                        d="M14 25.85C14.9 24.9667 15.9458 24.2708 17.1375 23.7625C18.3292 23.2542 19.6167 23 21 23C22.3833 23 23.6708 23.2542 24.8625 23.7625C26.0542 24.2708 27.1 24.9667 28 25.85V13H14V25.85ZM21 21C21.9667 21 22.7917 20.6583 23.475 19.975C24.1583 19.2917 24.5 18.4667 24.5 17.5C24.5 16.5333 24.1583 15.7083 23.475 15.025C22.7917 14.3417 21.9667 14 21 14C20.0333 14 19.2083 14.3417 18.525 15.025C17.8417 15.7083 17.5 16.5333 17.5 17.5C17.5 18.4667 17.8417 19.2917 18.525 19.975C19.2083 20.6583 20.0333 21 21 21ZM14 29C13.45 29 12.9792 28.8042 12.5875 28.4125C12.1958 28.0208 12 27.55 12 27V13C12 12.45 12.1958 11.9792 12.5875 11.5875C12.9792 11.1958 13.45 11 14 11H28C28.55 11 29.0208 11.1958 29.4125 11.5875C29.8042 11.9792 30 12.45 30 13V27C30 27.55 29.8042 28.0208 29.4125 28.4125C29.0208 28.8042 28.55 29 28 29H14ZM16 27H26V26.75C25.3 26.1667 24.525 25.7292 23.675 25.4375C22.825 25.1458 21.9333 25 21 25C20.0667 25 19.175 25.1458 18.325 25.4375C17.475 25.7292 16.7 26.1667 16 26.75V27ZM21 19C20.5833 19 20.2292 18.8542 19.9375 18.5625C19.6458 18.2708 19.5 17.9167 19.5 17.5C19.5 17.0833 19.6458 16.7292 19.9375 16.4375C20.2292 16.1458 20.5833 16 21 16C21.4167 16 21.7708 16.1458 22.0625 16.4375C22.3542 16.7292 22.5 17.0833 22.5 17.5C22.5 17.9167 22.3542 18.2708 22.0625 18.5625C21.7708 18.8542 21.4167 19 21 19Z"
                                        fill="#2D2F31"
                                      />
                                    </svg>
                                    <span>Badges</span>
                                  </div>
                                </Link>
                                <Link to={"/trophies"}>
                                  <div className="portal">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="42"
                                      height="42"
                                      viewBox="0 0 42 42"
                                      fill="none"
                                    >
                                      <path
                                        d="M19.925 22.05L25.6 16.4L24.175 14.975L19.925 19.225L17.8 17.1L16.4 18.5L19.925 22.05ZM10 29V27H32V29H10ZM13 26C12.45 26 11.9792 25.8042 11.5875 25.4125C11.1958 25.0208 11 24.55 11 24V13C11 12.45 11.1958 11.9792 11.5875 11.5875C11.9792 11.1958 12.45 11 13 11H29C29.55 11 30.0208 11.1958 30.4125 11.5875C30.8042 11.9792 31 12.45 31 13V24C31 24.55 30.8042 25.0208 30.4125 25.4125C30.0208 25.8042 29.55 26 29 26H13ZM13 24H29V13H13V24Z"
                                        fill="#2D2F31"
                                      />
                                    </svg>
                                    <span>Trophies</span>
                                  </div>
                                </Link>
                                <Link to={"/patches-page"}>
                                  <div className="portal">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="42"
                                      height="42"
                                      viewBox="0 0 42 42"
                                      fill="none"
                                    >
                                      <path
                                        d="M16 25H18V20H16V25ZM24 25H26V15H24V25ZM20 25H22V22H20V25ZM20 20H22V18H20V20ZM14 29C13.45 29 12.9792 28.8042 12.5875 28.4125C12.1958 28.0208 12 27.55 12 27V13C12 12.45 12.1958 11.9792 12.5875 11.5875C12.9792 11.1958 13.45 11 14 11H28C28.55 11 29.0208 11.1958 29.4125 11.5875C29.8042 11.9792 30 12.45 30 13V27C30 27.55 29.8042 28.0208 29.4125 28.4125C29.0208 28.8042 28.55 29 28 29H14ZM14 27H28V13H14V27Z"
                                        fill="#2D2F31"
                                      />
                                    </svg>
                                    <span>Patches</span>
                                  </div>
                                </Link>
                                
                              </Dropdown.Menu> */}
                            </Dropdown>
                          </div>
                        </div>
                      </li>
                      <li className="avatar">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-top" style={{position:"fixed"}}>
                              Profile
                            </Tooltip>
                          }
                        >
                          <a href="#">
                            <img
                              src={user?.photoURL || avatar}
                              alt="avatar"
                              className={`avatar ${activeDropdown === 1 ? "active" : ""
                                } `}
                              onClick={(event) => handleAnchorClick(1, event)}
                            />
                          </a>
                        </OverlayTrigger>
                        <div
                          className={`dropdown-div profile ${activeDropdown === 1 ? "active" : ""
                            } `}
                        >
                          <div className="img-wrapper">
                            <img src={user?.photoURL || avatar} alt="avatar" />
                          </div>
                          <div className="content-wrapper">
                            <p className="name">
                              Hello, {auth?.currentUser?.displayName}
                            </p>
                            <span className="span">
                              <a href="#">Ilmiya</a>
                            </span>
                            <ul className="list">
                              {/* <li><a href="#" className='online'>
                                <div className="text"><FaCircle /><span>Offline</span></div>
                              </a></li> */}
                              <li onClick={profileHandler}>
                                <a href="#">
                                  <TbUserSquare />
                                  <span>Profile</span>
                                </a>
                              </li>
                              <li>
                                <a href={redirect_uris?.Admin}>
                                  <CgNotes />
                                  <span>Settings</span>
                                </a>
                              </li>
                              <li onClick={logout}>
                                <a href="#">
                                  <MdLogout />
                                  <span>Logout</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div >
      </header >
      <div
        id="overLay"
        className={sidebarClassMobile}
        onClick={removeActiveClass}
      ></div>
      {
        sidebar ? (
          <div className="main-dashboard-wrapper">
            <div
              id="side-bar-wrapper"
              className={`${sidebarClass} ${sidebarClassMobile}`}
            >
              <div className="side-bar-title-wrapper">
                <Link to="/">
                  <img src={require("./analyticsIcon.png")} alt="analyticsIcon" />
                  <strong> {document.location.pathname.split("/")[1]}</strong>
                </Link>
              </div>
              <div className="sidebar-menu-wrapper">
                <ul className="sidebar-menu">
                  {sidebarUris.map((uri, index) => (
                    <li key={index}>
                      <Link to={uri.path}>
                        {uri.icon}
                        <span className="span">{uri.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="close-icon-wrapper">
                <a
                  href="#"
                  className="sidebar-toggle-close"
                  onClick={toggleHideClass}
                >
                  <img
                    src={require("./sideBarColorIcon.png")}
                    alt="sideBarColorIcon"
                  />
                </a>
              </div>
            </div>
            <div id="main-page-body-wrapper" className={sidebarClass}>
              <div className="container-fluid">{children}</div>
            </div>
          </div>
        ) : (
          children
        )
      }
    </div >
  );
};

export default IlmPortalNavbar;
