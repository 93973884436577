// ** Reducers Imports
import auth from "./pagesSlices/authSlice";
import model from "./layoutSlices/modelSlice";
import owner from "./pagesSlices/ownerSlice";
import group from "./pagesSlices/groupSlice";
import user from "./pagesSlices/userSlice";
import contact from "./pagesSlices/contactSlice";
import address from "./pagesSlices/addressSlice";
import organization from "./pagesSlices/organizationSlice";
import listings from "./pagesSlices/listingsSlice";

import error from "./errors/handleErrorsAndPayloads";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  listings,
  organization,
  address,
  group,
  user,
  contact,
  owner,
  error,
  auth,
  model,
});

export default rootReducer;
