import React, { useEffect, useState } from "react";
import { Button, Badge } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import AOS from "aos";
import "aos/dist/aos.css";
import { redirect_uris, navLink } from "../constants";
import Header from "../console/components/Header";
import LeftSidebarBase from "../console/modals/leftSidebarBase";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import PeopleIcon from '@mui/icons-material/People';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import AssessmentIcon from '@mui/icons-material/Assessment';
import { getAuth } from "firebase/auth";
import { FaUserShield, FaUserTie, FaChalkboardTeacher, FaUser, FaUserFriends, FaUserPlus, FaUserEdit } from 'react-icons/fa';
import show from "../../src/assets/show_chart .svg"
import group from "../../src/assets/groups.svg"
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { MdLabelOutline } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { TbUserSquare } from "react-icons/tb";
import { MdOutlineAnalytics } from "react-icons/md";
import { PiNotebook } from "react-icons/pi";

const rolesIcons = [
  { name: 'Admin', icon: <FaUserShield size={18} style={{ marginInline: '10px' }} /> },
  { name: 'Owner', icon: <FaUserTie size={18} style={{ marginInline: '10px' }} /> },
  { name: 'Teacher', icon: <FaChalkboardTeacher size={18} style={{ marginInline: '10px' }} /> },
  { name: 'User', icon: <FaUser size={18} style={{ marginInline: '10px' }} /> },
  { name: 'Parent', icon: <FaUserFriends size={18} style={{ marginInline: '10px' }} /> },
  { name: 'Customer', icon: <FaUserPlus size={18} style={{ marginInline: '10px' }} /> },
  { name: 'Editor', icon: <FaUserEdit size={18} style={{ marginInline: '10px' }} /> }
];


AOS.init();
export default function Dashboard({ roles }) {
  console.log("🚀 ~ file: Dashboard.js:7 ~ redirect_uris:", redirect_uris)
  console.log("🚀 ~ file: Dashboard.js:23 ~ roles:", roles);
  const customToken = localStorage.getItem("custom-token");

  const space = JSON.parse(localStorage.getItem("space"));

  const { currentUser: user } = getAuth();

  const navIcon = [
    // <SupportOutlinedIcon />, <InboxOutlinedIcon />
  ];
  const badgeVariants = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "haseeb",
  ];

  const organizingRoles = new Set(roles.map((role) => role.toLowerCase()));

  const filteredLinks = navLink.filter((link) =>
    link.role.some((role) => organizingRoles.has(role.toLowerCase()))
  );

  return (
    <div>
      <LeftSidebarBase />

      <div className="auth-body-outer" id="dashboardBodyOuter">
        <div className="dashboard-body">
          <div className="body-content">
            <div className="name">    <h1> Welcome <span>{user?.displayName} {""}</span> </h1>
              <DropdownButton
                // as={ButtonGroup}
                id={`dropdown-button-drop-end`}
                drop='end'
                variant="secondary"
                title={<IoMdInformationCircleOutline />}
              >
                <Dropdown.Item as="button">
                  <p>
                    Accelerate your journey with our Quick Start Guide. Streamlined steps to get you up and running in no time!    <a href="https://docs.ilmiya.com/" target="_blank" rel="noreferrer">here</a>
                  </p>

                </Dropdown.Item>
              </DropdownButton>
            </div>
            <h5>
              Your Organization is <a href="#"> {space?.name}</a>
            </h5>
            {/* <h6>Organizing ID  : <span>{space?.id ?? user?.org_id}</span> </h6> */}
            <h6>
              Display Name :<span> {space?.displayName ?? space?.name}</span>
            </h6>
            <div className="role">
              <h6>
                Your Role :
              </h6>
              {roles && Array.isArray(roles) && roles.length ? (
                roles.map((role, index) => (
                  <span key={index}>
                    <Badge
                      style={{ width: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '5px', flexDirection: 'row' }}
                      variant={badgeVariants[badgeVariants.length % index]}
                    >
                      {rolesIcons.find((icon) => icon.name === role)?.icon}
                      {role}
                    </Badge>
                  </span>
                ))
              ) : (
                <span style={{ color: "red" }}>
                  {" "}
                  You Have Assign no Role. Contact Your Support Team!
                </span>
              )}
            </div>

            <div className="add-btns">
              <>
                <a
                  href={
                    redirect_uris.People + `?token=${customToken}`
                  }
                >
                  <Button
                    variant="outline-primary"
                    id="addUserId"
                    name="addUserName"
                    className="addUserClass"
                  >
                    <i className="fa-solid fa-plus"></i>
                    Add Student
                  </Button>
                </a>
                <a href={redirect_uris.People + `?token=${customToken}`}>
                  <Button
                    variant="outline-primary"
                    id="addContactId"
                    name="addContactName"
                    className="addContactClass"
                  >
                    <i className="fa-solid fa-plus"></i>
                    Add Member
                  </Button>
                </a>
                <a href={redirect_uris.Studio + `assignment/?token=${customToken}`}>
                  <Button
                    variant="outline-primary"
                    id="addContactId"
                    name="addContactName"
                    className="addContactClass"
                  >
                    <i className="fa-solid fa-plus"></i>
                    Assign
                  </Button>
                </a>
              </>
            </div>

            <h3>Quick Access</h3>

            <div className="row">

              {/* <div className="col-lg-4 col-md-6">
                <a target="blank"  href={redirect_uris.People + `?token=${customToken}`} >
                  <div
                    className="access-box Users"
                    id="UsersId"
                    name="UsersName">

                    <div className="icon">
                      <GroupOutlinedIcon />
                      <h4>Users</h4>
                      <img src={show} alt=""/>
                    </div>

                    <div className="text">
                      <p>Use to see reports to Organizing.</p>
                    </div>
                  </div>
                </a>
              </div> */}

              {/* <div className="col-lg-4 col-md-6">
                <a
                  target="blank"
                  href={redirect_uris.People + `?token=${customToken}`}
                >
                  <div
                    className="access-box classname"
                    id="ContactsId"
                    name="ContactsName"
                  >
                    <div className="icon">
                      <ContactsOutlinedIcon />
                      <h4>Contacts</h4>
                      <img src={show} alt=""/>

                    </div>
                    <div className="text">
                      <p>Use this to add contact Organizing.</p>
                    </div>
                  </div>
                </a>
              </div> */}
              {/* <div className="col-lg-4 col-md-6">
                <a
                  target="blank"
                  href={
                    redirect_uris.Analytics +
                    "ClassRoom" +
                    `?token=${customToken}`
                  }
                >
                  <div
                    className="access-box classname"
                    id="ReportsId"
                    name="ReportsName"
                  >
                    <div className="icon">
                      <AssessmentIcon />
                      <h4>Reports</h4>
                      <img src={show} alt=""/>

                    </div>
                    <div className="text">
                      <p>
                        Reports for the current quarter are due by the end of
                        the week
                      </p>
                    </div>
                  </div>
                </a>
              </div> */}
              {/* <div className="col-lg-4 col-md-6">
                <a
                  // href={
                  //   redirect_uris.Console + "gateways" + `?token=${customToken}`
                  // }
                  target="blank"
                >
                  <div
                    className="access-box classname"
                    id="SubscriptionsId"
                    name="SubscriptionsName"
                  >
                    <div className="icon">
                      <ThumbUpAltOutlinedIcon />
                      <h4>Subscriptions</h4>
                      <img src={show} alt=""/>

                    </div>
                    <div className="text">
                      <p>
                        Don't forget to renew your subscription before it
                        expires
                      </p>
                    </div>
                  </div>
                </a>
              </div> */}

              {/* <div className="col-lg-4 col-md-6">
                <a
                  href={
                    redirect_uris.Learn
                  }
                  target="blank"
                >
                  <div className="access-box classname" id="LXPId" name="LXPName" >
                    <div className="icon">
                      <SchoolOutlinedIcon />
                      <h4>LXP</h4>
                      <img src={show} alt=""/>
                    </div>
                    <div className="text">
                      <p>Use to LXP of Organizing.</p>
                    </div>
                  </div>
                </a>
              </div> */}

              <div className="col-lg-4 col-md-6">
                <div className="quick-access-card">
                  <a
                    href={redirect_uris.People + `?token=${customToken}`}
                    target="blank"
                  >
                    <div
                      className="access-box People"
                      id="PeopleId"
                      name="PeopleName"
                    >
                      <div className="icon">
                        <TbUserSquare />
                        <h4>People</h4>
                        <img src={show} alt="show" />

                      </div>
                      <div className="text">
                        <p>
                          Access courses, submit assignments, and track your progress seamlessly on our platform.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="quick-access-card">
                  <a
                    href={redirect_uris.People + `?token=${customToken}`}
                    target="blank"
                  >
                    <div
                      className="access-box People"
                      id="PeopleId"
                      name="PeopleName"
                    >
                      <div className="icon">
                        <img src={group} />
                        <h4>Team</h4>
                        <img src={show} alt="" />

                      </div>
                      <div className="text">
                        <p>
                          Collaborate efficiently, share resources, and coordinate projects seamlessly on our platform.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="quick-access-card">
                  <a
                    href={redirect_uris.Studio + `?token=${customToken}`}
                    target="blank"
                  >
                    <div
                      className="access-box Studio"
                      id="StudioId"
                      name="StudioName"
                    >
                      <div className="icon">
                        <PiNotebook />
                        <h4>Engage</h4>
                        <img src={show} alt="" />
                      </div>
                      <div className="text">
                        <p>
                          Effortlessly manage and submit assignments, ensuring a streamlined workflow for academic success
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="quick-access-card">
                  <a
                    href={redirect_uris.Analytics + `?token=${customToken}`}
                    target="blank"
                  >
                    <div
                      className="access-box Analytics"
                      id="AnalyticsId"
                      name="AnalyticsName"
                    >
                      <div className="icon">
                        <MdOutlineAnalytics />
                        <h4>Analytics</h4>
                        <img src={show} alt="" />
                      </div>
                      <div className="text">
                        <p>
                          Visualize data and trends with ease using our intuitive Charts feature for clear insights.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="quick-access-card">
                  <a
                    href={redirect_uris.Admin + `?token=${customToken}`}
                    target="blank"
                  >
                    <div
                      className="access-box Analytics"
                      id="AnalyticsId"
                      name="AnalyticsName"
                    >
                      <div className="icon">
                        <MdOutlineAdminPanelSettings />
                        <h4>Admin</h4>
                        <img src={show} alt="" />
                      </div>
                      <div className="text">
                        <p>
                          Stay connected and informed with real-time updates through our dynamic Streams feature.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="quick-access-card">
                  <a
                    href={redirect_uris.Admin_label + `?token=${customToken}`}
                    target="blank"
                  >
                    <div
                      className="access-box Analytics"
                      id="AnalyticsId"
                      name="AnalyticsName"
                    >
                      <div className="icon">
                        <MdLabelOutline />
                        <h4>Labels</h4>
                        <img src={show} alt="" />
                      </div>
                      <div className="text">
                        <p>
                          Organize and categorize information effortlessly with our user-friendly Labels feature.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              {/* {(organizingRole.includes("Owner") ||
                organizingRole.includes("Customer")) && ( */}
              {/* <div className="col-lg-4 col-md-6">
                <a
                  href={
                    redirect_uris.People + "classes" + `?token=${customToken}`
                  }
                  target="blank"
                >
                  <div
                    className="access-box assignClass"
                    id="assignClassId"
                    name="assignClassName"
                  >
                    <div className="icon">
                      <LocalPoliceOutlinedIcon />
                      <h4>Assign Class</h4>
                      <img src={show} alt=""/>
                    </div>
                    <div className="text">
                      <p>Assign Class use to assign student to class.</p>
                    </div>
                  </div>
                </a>
              </div> */}

              {/* {(organizingRole.includes("Owner") ||
                organizingRole.includes("Customer")) && ( */}
              {/* <div className="col-lg-4 col-md-6">
                <a
                  href={
                    redirect_uris.Studio +
                    "Catalogs" +
                    `?token=${customToken}`
                  }
                  target="blank"
                >
                  <div
                    className="access-box assignCourse"
                    id="assignCourseId"
                    name="assignCourseName"
                  >
                    <div className="icon">
                      <AssignmentIcon />
                      <h4>Assign Course</h4>
                      <img src={show} alt=""/>
                    </div>
                    <div className="text">
                      <p>
                        Assign Course use to assign course to student &
                        course......
                      </p>
                    </div>
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
