import React, { useState, useEffect } from "react";
import { auth } from "../config/firebase";
import { useDispatch } from "react-redux";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { navLink, redirect_uris, space } from "../constants";
import IlmPortalNavbar from "../components/ilm-nav/IlimiyaNavigation.jsx";
import { useSelector } from "react-redux";
import { logout } from "../helpers/logout.js";
import { ApiRequests } from "../service/ApiRequests.js";

const HeaderLayout = ({ children }) => {
  const customToken = localStorage.getItem("custom-token");

  const d = useDispatch();
  const modelState = useSelector((s) => s?.model?.modelState?.LeftSidebarBase);
  const [orgsName, setOrgsName] = useState("");
  console.log("🚀 ~ HeaderLayout ~ orgsName:", orgsName)
  const [orgs, setOrgs] = useState({
    limit: 10,
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1,
  });
  const fetchOrgs = async (params) => {
    const res = await ApiRequests.getOrganizationsMembership({
      ...params,
      ...(!!orgsName ? { name: orgsName } : {}),
    });
    setOrgs(res.data);
  };
  useEffect(() => {
    fetchOrgs({});
  }, [orgsName]);
  const toggleMenu = () => {
    // d(
    //   handleModel({
    //     model: "LeftSidebarBase",
    //     state: !modelState,
    //     args: { resource: "leftSidebar" },
    //   })
    // );
  };
  return (
    <>
      <IlmPortalNavbar
        onPortalChange={(e) =>
          (window.location.href = redirect_uris[e] + `?token=${customToken}`)
        }
        sidebar={false}
        onSwitchOrg={() => {
          window.location.href = redirect_uris.Login + "organizations";
        }}
        onChangeOrgName={(e) => {
          setOrgsName(e);
        }}
        onChangeOrg={(org) => {
          window.location.href = window.location.href.replace(
            space,
            org.displayName
          );
        }}
        sidebarUris={navLink}
        orgs={orgs.results}
        auth={auth}
        urls={navLink}
        logout={logout}
        toggleMenu={toggleMenu}
        menuState={true}
        switchPopup={true}
      >
        {children}
      </IlmPortalNavbar>
    </>
  );
};
export default HeaderLayout;
