import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";

const localhost = process.env.REACT_APP_NODE_ENV === "localhost";
const development = process.env.REACT_APP_NODE_ENV == "development";
const staging = process.env.REACT_APP_NODE_ENV == "staging";
export const app_mode = localhost
  ? "localhost"
  : development
  ? "development"
  : staging
  ? "staging"
  : "production";

export const basePath =
  process.env.REACT_APP_basePath ??
  (development
    ? "http://192.168.3.116:6060/iam/api/"
    : staging
    ? "https://api.ilmiya.dev/iam/api"
    : "https://api.ilmiya.com/iam/api");
export const basePathInvite =
  process.env.REACT_APP_basePath_invite ??
  (development
    ? "http://192.168.3.116:6065/users/api/"
    : staging
    ? "https://api.ilmiya.dev/users/api"
    : "https://api.ilmiya.com/users/api");

export const APIurls = {
  // auth
  login: "auth/login",
  refreshTokens: "auth/refresh-tokens",
  register: "auth/register",
  logout: "auth/logout",
  authenticate: "auth/authenticate",
  revokeToken: "auth/customToken/revoke",

  organizations: "organizations",
  profile: basePathInvite + "profile",
  bymail: "organizations/by-mail",
  membership: "organizations/membership",

  members: basePathInvite + "users",
  invites: basePathInvite + "invites",
  inviteUser: basePathInvite + "users/invite",
  userExport: basePathInvite + "users/export",
  userImport: basePathInvite + "users/import",
  userRole: basePathInvite + "users/rolename/role",

  groups: "group",
  addresses: "addresses",
};

export const allowedRoles = ["Owner", "Admin", "Customer"];
export function getOrganization() {
  try {
    return JSON.parse(localStorage.getItem("space")) || {};
  } catch (error) {
    return {};
  }
}

export const organization = getOrganization;

// Function to update the space variable
const updateSpace = () => {
  const pathArray = window.location.pathname.split("/");
  const consoleValue = pathArray[2];
  return !!organization?.displayName ? organization?.displayName : consoleValue;
};

// Initial assignment of space variable
export let space = updateSpace();
console.log("space:::", space);
const handleStorageChange = () => {
  space = updateSpace();
};

// Add the storage event listener
var originalSetItem = localStorage.setItem;
localStorage.setItem = function () {
  handleStorageChange();
  originalSetItem.apply(this, arguments);
};

export const redirect_uris = {
  Login: localhost
    ? `http://localhost:3000/`
    : development
    ? `https://accounts.ilmiya.dev/`
    : staging
    ? `https://accounts.ilmiya.app/`
    : `https://accounts.ilmiya.com/`,
  Accounts: localhost
    ? `http://${space}.us.localhost:3001/`
    : development
    ? `https://${space}.myilmiya.dev/`
    : staging
    ? `https://${space}.myilmiya.app/`
    : `https://${space}.myilmiya.com/`,
  Learn: localhost
    ? `http://${space}.us.localhost:3002/lxp/`
    : development
    ? `https://${space}.myilmiya.dev/lxp/`
    : staging
    ? `https://${space}.myilmiya.app/lxp/`
    : `https://${space}.myilmiya.com/lxp/`,
  Studio: localhost
    ? `http://localhost:3004/engage/${space}/`
    : development
    ? `https://platform.ilmiya.dev/engage/${space}/`
    : staging
    ? `https://platform.ilmiya.app/engage/${space}/`
    : `https://platform.ilmiya.com/engage/${space}/`,
  People: localhost
    ? `http://localhost:3008/people/${space}/`
    : development
    ? `https://platform.ilmiya.dev/people/${space}/`
    : staging
    ? `https://platform.ilmiya.app/people/${space}/`
    : `https://platform.ilmiya.com/people/${space}/`,
  Teacher: localhost
    ? `http://localhost:3013/teacher/${space}/`
    : development
    ? `https://platform.ilmiya.dev/teacher/${space}/`
    : staging
    ? `https://platform.ilmiya.app/teacher/${space}/`
    : `https://platform.ilmiya.com/teacher/${space}/`,
  Analytics: localhost
    ? `http://localhost:3014/analytics/${space}/`
    : development
    ? `https://platform.ilmiya.dev/analytics/${space}/`
    : staging
    ? `https://platform.ilmiya.app/analytics/${space}/`
    : `https://platform.ilmiya.com/analytics/${space}/`,
  Console: localhost
    ? `http://localhost:3015/console/${space}/`
    : development
    ? `https://platform.ilmiya.dev/console/${space}/`
    : staging
    ? `https://platform.ilmiya.app/console/${space}/`
    : `https://platform.ilmiya.com/console/${space}/`,

    Admin: localhost
    ? `http://localhost:3005/admin/${space}/`
    : development
    ? `https://platform.ilmiya.dev/admin/${space}/`
    : staging
    ? `https://platform.ilmiya.app/admin/${space}/`
    : `https://platform.ilmiya.com/admin/${space}/`,

  Admin_label: localhost
    ? `http://localhost:3005/admin/${space}/label/`
    : development
    ? `https://platform.ilmiya.dev/admin/${space}/label/`
    : staging
    ? `https://platform.ilmiya.app/admin/${space}/label/`
    : `https://platform.ilmiya.com/admin/${space}/label/`,
};

export const icons = {
  // "Login": <PasswordOutlinedIcon />,
  // Accounts: <ManageAccountsOutlinedIcon />,
  Learn: <SchoolOutlinedIcon />,
  // "Platform": <SpaceDashboardOutlinedIcon />,
  Studio: <EditNoteOutlinedIcon />,
  // "Read": <AutoStoriesOutlinedIcon />,
  // Dashboard: <SpaceDashboardOutlinedIcon />,
  People: <PeopleOutlinedIcon />,
  // "Library": <LocalLibraryOutlinedIcon />,
  // Center: <LocalPoliceOutlinedIcon />,
  // Admin: <AdminPanelSettingsIcon />,
  // Teacher: <CastForEducationIcon />,
  Analytics: <SpaceDashboardOutlinedIcon />,
  Console: <EditNoteOutlinedIcon />,
};

export const redirect_uri = redirect_uris.Console;
import { FcInvite } from "react-icons/fc";
import { GrUserAdmin } from "react-icons/gr";
import { FaTags } from "react-icons/fa";
import { GrGateway } from "react-icons/gr";
import { RiOrganizationChart } from "react-icons/ri";
import { MdBrandingWatermark } from "react-icons/md";
import { FaSignature } from "react-icons/fa";
import { SiThemodelsresource } from "react-icons/si";
import { BiSolidDashboard } from "react-icons/bi";
import { MdAdminPanelSettings } from "react-icons/md";
import { HiUsers } from "react-icons/hi2";

export const navLink = [
  {
    name: "Dasboard",
    icon: <BiSolidDashboard />,
    path: `/`,
    role: ["Owner", "Admin", "Customer", "Editor", "User", "Viewer"],
  },
  // {
  //   name: "Members",
  //   icon:
  //     <HiUsers />,
  //   path: `/member`,
  //   role: ["Owner", "Admin", "Editor", "User", "Viewer"],

  // },
  // {
  //   name: "Roles",
  //   icon:
  //     <MdAdminPanelSettings />,
  //   path: `/roles`,
  //   role: ["Owner", "Admin", "Editor", "User", "Viewer"],

  // },
  // {
  //   name: "Invites",
  //   icon: <FcInvite />,
  //   path: `/invites`,
  //   role: ["Owner", "Admin", "Editor", "User", "Viewer"],
  // },
  // {
  //   name: "Generals",
  //   icon:
  //     <GrUserAdmin />,
  //   path: `/general`,
  //   role: ["Owner", "Admin", "Editor", "User", "Viewer"],
  // },
  // {
  //   name: "Gateways",
  //   icon:
  //     <GrGateway />,
  //   path: `/gateways`,
  //   role: ["Owner", "Admin", "Editor", "User", "Viewer"],

  // },
  // {
  //   name: "Tags",
  //   icon:
  //     <FaTags />,
  //   path: `/tags`,
  //   role: ["Owner", "Admin", "Editor", "User", "Viewer"],

  // },
  // {
  //   name: "organization",
  //   icon:
  //     <RiOrganizationChart />,
  //   path: `/organization`,
  //   role: ["Owner", "Admin", "Editor", "User", "Viewer"],

  // },
  // {
  //   name: "Branding",
  //   icon:
  //     <MdBrandingWatermark />,
  //   path: `/branding`,
  //   role: ["Owner", "Admin", "Editor", "User", "Viewer"],
  // },
  // {
  //   name: "Signature",
  //   icon:
  //     <FaSignature />,
  //   path: `/signature`,
  //   role: ["Owner", "Admin", "Editor", "User", "Viewer"],
  // },
  // {
  //   name: "Models",
  //   icon:
  //     <SiThemodelsresource />,
  //   path: `/models`,
  //   role: ["Owner", "Admin", "Editor", "User", "Viewer"],
  // },
];
